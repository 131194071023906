<template>
  <div>
    <HomeThreeTwo />
    <div id="ad-container">
    </div>
  </div>
</template>

<script>
import HomeThreeTwo from "../components/HomeThree/_v2/index";
export default {
  components: { HomeThreeTwo },
  mounted() {

  }
};
</script>

<style>
</style>