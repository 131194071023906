<template>
  <div
    :class="[
      'post_gallery_sidebar',
      darkClass && 'section' in darkClass ? darkClass.section : '',
    ]"
  >
    <div class="tab-content">
      <div
        :class="[
          'tab-pane fade',
          selectedGallery === 'trendy' ? 'show active' : '',
        ]"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="post_gallery_items" v-if="datas && datas.length > 0">
          <template v-for="(small, index) in datas.slice(0, 5)">
            <row-card
              :class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
              :category="true"
              :datas="small"
              :key="index"
            />
          </template>
        </div>
      </div>
    </div>
    <div v-if="ad" class="sidebar-add pt-35">
      <a href="#"><img src="@/assets/images/ads/two_ad.jpg" alt="ad" /></a>
    </div>
  </div>
</template>

<script>
import RowCard from "../Cards/RowCard.vue";
export default {
  components: { RowCard },
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    darkClass: {
      type: Object,
      default: () => {},
    },
    signup: {
      type: Boolean,
      default: true,
    },
    trendingBigPost: {
      type: Boolean,
      default: true,
    },
    trendingShortPost: {
      type: Boolean,
      default: true,
    },
    ad: {
      type: Boolean,
      default: true,
    },
    sharePost: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    selectedGallery: "trendy",
    trendingNews: {
      autoplay: true,
      slideToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      dots: false,
    },
  }),
  methods: {
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
    trendingNewsPrev() {
      this.$refs.trendingNews.prev();
    },
    trendingNewsNext() {
      this.$refs.trendingNews.next();
    },
  },
};
</script>

<style></style>
