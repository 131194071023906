import Vue from 'vue'
import VueRouter from 'vue-router'
import fourOfour from '../components/404.vue'
import PostOne from '../components/Posts/PostOne/index.vue'
import PostThree from '../components/Posts/PostThree/index'
import PostTwo from '../components/Posts/PostTwo/index.vue'
import Business from '../components/category/Buisness.vue'
import Entertainment from '../components/category/Entertainment.vue'
import Features from '../components/category/Feature.vue'
import Sports from '../components/category/Sports.vue'
import Trending from '../components/category/Trending.vue'
import HomeThree_two from '../views/HomeThree_two'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeThree_two
  },
 
  {
    path: '/404',
    name: '404',
    component: fourOfour
  },
  {
    path: '/posts/:id',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: '/posts/posttwo',
    name: 'PostTwo',
    component: PostTwo
  },
  {
    path: '/posts/postthree',
    name: 'PostThree',
    component: PostThree
  },
  {
    path: '/business',
    name: 'Business',
    component: Business
  },
  {
    path: '/entertainment',
    name: 'Entertainment',
    component: Entertainment
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/sports',
    name: 'Sports',
    component: Sports
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Trending
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
