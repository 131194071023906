<template>
  <div>
    <video-pop-up />
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <header-three @toggleSidebar="toggleSidebar" />
    <trending-post
      class="pt-65"
      :datas="trendingPost || []"
      :buisnessData="buisnessData || []"
    />
    <Footer />
    <back-to-top />
  </div>
</template>

<script>

import { supabase } from "../../../utils/supabase";
import internationalPost from "../../Data/InernationalPost";
import post from "../../Data/post";
import trendingPost from "../../Data/TrendingHomeThree";
import BackToTop from "../../Helpers/BackToTop.vue";
import Drawer from "../../Mobile/Drawer.vue";
import headerThree from "../../Utility/HomeThree/index";
import VideoPopUp from "../../Utility/VideoPopUp.vue";
import Footer from "../Footer";
import TrendingPost from "../TrendingPost";

export default {
  components: {
    VideoPopUp,
    Drawer,
    headerThree,
    TrendingPost,
    Footer,
    BackToTop,
  },

  data: () => ({
    sidebar: false,
    post: post.data,
    trendingPost: [],
    buisnessData: trendingPost.buisness,
    internationalPost: internationalPost.international,
    opinionPost: internationalPost.opinionNews,
    scienceNews: internationalPost.scienceNews,
  }),
  mounted() {
    this.fetchPosts()
    document.body.classList.add("home-3-bg");
  },
  beforeDestroy() {
    document.body.classList.remove("home-3-bg");
  },
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    fetchPosts() {
      console.log('fetching posts', this.sidebar);
      const { error } = supabase
        .from("posts").select("*").then((res) => {
          console.log('res', res);
          this.trendingPost = res.data;
        });
      if (error) {
        console.log(error);
      } 
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style>
</style>