var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'post_gallery_sidebar',
    _vm.darkClass && 'section' in _vm.darkClass ? _vm.darkClass.section : '',
  ]},[_c('div',{staticClass:"tab-content"},[_c('div',{class:[
        'tab-pane fade',
        _vm.selectedGallery === 'trendy' ? 'show active' : '',
      ],attrs:{"role":"tabpanel","aria-labelledby":"pills-home-tab"}},[(_vm.datas && _vm.datas.length > 0)?_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.datas.slice(0, 5)),function(small,index){return [_c('row-card',{key:index,class:[_vm.darkClass && 'item' in _vm.darkClass ? _vm.darkClass.item : ''],attrs:{"category":true,"datas":small}})]})],2):_vm._e()])]),(_vm.ad)?_c('div',{staticClass:"sidebar-add pt-35"},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/ads/two_ad.jpg"),"alt":"ad"}})])
}]

export { render, staticRenderFns }