<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <HeaderThree @toggleSidebar="toggleSidebar" />
    <!--====== POST LAYOUT 1 PART START ======-->
    <section class="post-layout-1-area pb-80 mt-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="post-layout-top-content">
              <div
                class="
                  post-categories
                  d-flex
                  justify-content-between
                  align-content-center
                "
              >
                <div class="categories-item">
                  <span>Tin Nhanh</span>
                </div>
                <div class="categories-share">
                  <ul>
                    <li><i class="fas fa-comment"></i>45020</li>
                    <li><i class="fas fa-fire"></i>45020</li>
                  </ul>
                </div>
              </div>
              <div class="post-content">
                <h3 class="title">
                  {{ post?.title }}
                </h3>
    <div id="ads-2"></div>

                <div v-html="post?.content" />
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <style-one :datas="Posts" />
          </div>
        </div>
      </div>
    </section>

    <our-latest-news :Posts="Posts" />
  
  </div>
</template>

<script>
import HeaderThree from "../../Utility/HomeThree/index";
import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
import Posts from "../../Data/NewsRowCard";
import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
import Drawer from "../../Mobile/Drawer.vue";
import { supabase } from "../../../utils/supabase";
export default {
  components: { HeaderThree, StyleOne,  OurLatestNews, Drawer },
  data: () => ({
    Posts: Posts.data,
    sidebar: false,
    post: null
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  mounted() {
    this.fetchPosts();
  //   const script1 = document.createElement('script');
  //   script1.defer = true;
  //   script1.type = 'text/javascript';
  //   script1.innerHTML = `
  //    	atOptions = {
	// 	'key' : '9cec6477fc8bd6ab0979dc2ab66284a4',
	// 	'format' : 'iframe',
	// 	'height' : 120,
	// 	'width' : 800,
	// 	'params' : {}
	// };
  //   `;
  //   console.log(document.getElementById('ads-2'));
  //   document.getElementById('ads-2').appendChild(script1);

  //   const script2 = document.createElement('script');
  //   script2.type = 'text/javascript';
  //   script2.src = '//www.topcpmcreativeformat.com/9cec6477fc8bd6ab0979dc2ab66284a4/invoke.js';
  //   document.getElementById('ads-2').appendChild(script2);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    fetchPosts() {
      console.log('fetching posts', this.sidebar,this.$route.params);
      const { error } = supabase
        .from("posts").select("*").eq('id',this.$route.params.id).single().then((res) => {
          console.log('res', res);
          this.post = res.data;
        });
      if (error) {
        console.log(error);
      } 
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style></style>
